
import $app from '@/plugins/modules'
import { IAttachment } from '@/modules/system/types';
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatientPhoto } from '../types';

@Component
export default class CustomerPhoto extends Vue {
  @Prop({ default: () => { } })
  readonly value: IPatientPhoto | undefined;

  showImage = false;

  get photo(): Array<IAttachment> {
    const item = this.value ? this.value : null;
    if (item && item.id) {
      return [item];
    } else {
      return [];
    }
  }

  set photo(items: Array<IAttachment>) {
    const original = this.value ? this.value : null;
    if (original) {
      if (items.length > 0) {
        this.appendPhoto(original, items[0]);
      }
    }
  }

  async appendPhoto(photo: IPatientPhoto, img: IAttachment) {
    const attach = await $app.post('/api/attachments', img);
    photo.name = img.name;
    photo.mime = img.mime;
    photo.ext = img.ext;
    photo.file = img.file;
    photo.uploaded = img.uploaded;
    photo.image = img.image;
    photo.state = img.state;

    const formData = new FormData();
    formData.append('file', img.file!);
    await $app.binary('/api/attachments/' + attach.id + '/image', 'POST', {}, formData, 'multipart/form-data', (ev: any) => {
      if (ev.loaded && ev.total) {
        img.uploaded = ev.loaded / ev.total * 100;
      }
    });

    photo.id = attach.id;
    photo.url = '/api/attachments/' + photo.id + '/image';
    photo.uploaded = 100;

    await $app.post('/api/patients/' + photo.patientId + '/photos', photo);
    photo.uploaded = 0;
  }

  async open(img: IAttachment) {
    this.showImage = true;
  }
}
